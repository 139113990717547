import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useFirebase } from "../../contexts/FirebaseProvider";
import { auth_UI_Config } from '../../auth-config';
import { Route, useHistory } from 'react-router-dom';
import GuideDetail from './GuideDetail';
import GuidesList from './GuidesList';
import Button from '../UI/Button';
import styles from './Admin.module.css';


const Admin = () => {
  const history = useHistory();
  const ctx = useFirebase();
  const auth = ctx.auth;

  const selGuide = ctx.guide;
  const guides = ctx.guides;
  const alertOpacity = ctx.alertOpacity;
  const alertMsg = ctx.msg;
  const isErr = ctx.isErr;
  
  const persistOrUpdateGuide = ctx.persistOrUpdateGuide;
  const deSelectGuide = ctx.deSelectGuide;
  const selectGuide = ctx.selectGuide;
  const deleteGuide = ctx.deleteGuide;
  
  const [signedIn, setSignedIn] = useState(false);
  const [alertColor, setAlertColor] = useState('#1D7277');
  const color_OK = '#1D7277';
  const color_ERR = '#AF0606';

  useEffect(() => {
    setAlertColor(isErr ? color_ERR : color_OK);
  }, [alertOpacity])

  const createOrUpdateGuide = (_guide) => {
    persistOrUpdateGuide(_guide).then(() => {
      deSelectGuide();
// console.log(`Guide "${_guide.title}" done`)
      history.goBack();
    });
  }

  useEffect(() => {
    if (!selGuide) return;
    history.push('admin/guide');
  }, [selGuide])

  useEffect(() => {
    const unsubscribeAuthObserver = auth.onAuthStateChanged((user) => setSignedIn(!!user));
    return () => unsubscribeAuthObserver();
  })

  return (
    <>
      <div className={styles.Alert} style={{ opacity: alertOpacity, backgroundColor: alertColor }} >{alertMsg}</div>
      {signedIn
        ? (
          <div>
            <div className={styles.Admin}>
              <div className={styles.AdminSidebar}>
                <Button txt='New Guide' btnClicked={() => history.push('/admin/new-guide')} />
                <Button txt='Sign Out' btnClicked={() => auth.signOut()} />
              </div>
              <div className={styles.AdminContent}>
                <Route exact path='/admin'>
                  <GuidesList guides={guides} showGuide={selectGuide} deleteGuide={deleteGuide} />
                </Route>
                <Route exact path='/admin/guides'>
                  <GuidesList guides={guides} showGuide={selectGuide} deleteGuide={deleteGuide} />
                </Route>
                <Route exact path='/admin/new-guide'>
                  <GuideDetail upsetGuide={createOrUpdateGuide} />
                </Route>
                <Route exact path='/admin/guide'>
                  <GuideDetail guide={selGuide} upsetGuide={createOrUpdateGuide} />
                </Route>
              </div>
            </div>
          </div>
        )
        : (
          <div>
            <StyledFirebaseAuth uiConfig={auth_UI_Config} firebaseAuth={auth} />
          </div>
        )
      }
    </>
  )
}

export default Admin;
