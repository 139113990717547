import React from 'react';
import styles from './Card.module.css';

const Card = ({image, txt = 'safe personnal data', alternateTxt = 'card_img'}) => {
  return(
    <div className={styles.Card}>
      <img src={image} alt={alternateTxt} />
      <span data-testid='card_txt'>{txt}</span>
    </div>
  )
}

export default Card;
