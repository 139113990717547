import React from 'react';
import ActionBtns from '../ActionBtns';
import styles from './Common.module.css';

const HowWeHelp = () => {
  return (
    <div className={styles.Common}>
      <div className={styles.ContentNav}>
        <div>
          Frequently Asked Questions
        </div>
        <div className={styles.ContentNavBtns}>
          <ActionBtns />
        </div>
      </div>
      <div className={styles.Ads_1}></div>
      <div className={styles.Center}>

        <h4>What Is Personal Data?</h4>
        <p>
          Personal data refers to any information that can identify a living individual - either on its own,
          or if it is combined with other information you hold, or if it is combined with other information
          that is likely to come into your possession.
        </p><p>
          The definition of personal data is very broad and would include information such as a name, ID number,
          photo, email address, bank details, posts on social networking websites, medical information,
          or a computer IP address.
        </p>
        
        <h4>What Is Special Category Data?</h4>
        <p>
          This is a sub-category of personal data which is known as ‘special category’ data.
          This is personal data about an individual's:
          <ul>
            <li>Racial or ethnic origin</li>
            <li>Political opinions</li>
            <li>Religious or philosophical beliefs</li>
            <li>Trade union membership</li>
            <li>Physical or mental health</li>
            <li>Sex life or sexual orientation</li>
            <li>Biometrics (if used for identification purposes)</li>
            <li>Genetics</li>
          </ul>
          The GDPR places more restrictions on the processing of special category data than on
          other personal data, because it is particularly sensitive.
        </p>
        
        <h4>What Is A Data Subject?</h4>
        <p>
          A data subject is the individual that can be identified by the personal data.
        </p>
        
        <h4>What Is Processing?</h4>
        <p>
          Processing is any operation performed on personal data. This includes using, viewing,
          altering or deleting the data. If you hold, record or obtain personal data on a computer
          system or in a structured paper filing system, you will normally be considered to be
          processing personal data.
        </p>
        
        <h4>Who Is A Data Controller?</h4>
        <p>
          A data controller is a company or legal entity that decides how and why the personal
          data is to be used.
        </p>
        
        <h4>Who Is A Data Processor?</h4>
        <p>
          A data processor is any company or legal entity that processes data on behalf of the
          Data Controller (other than an employees of the data controller).
        </p>
        
        <h4>Who Is A Data Protection Officer (DPO)?</h4>
        <p>
          Data Protection Officer is the professional responsible for the data protection
          activities and measures inside the company. He/she holds the security leadership
          role in charge of overseeing data protection strategy and implementation to ensure
          compliance with GDPR requirements.
        </p>
        
        <h4>What Is A Data Breach?</h4>
        <p>
          If, as a result of a failure of a company’s processes, an individual or individuals’
          data is accidentally or unlawfully lost, altered, deleted in error, or passed onto
          an unauthorised third party then this is what is known as a “breach”.
        </p><p>
          It is any event that has the potential to affect the confidentiality, integrity or 
          availability of personal data held by a company.
        </p><p>
          Personal data breaches can happen for a number of reasons, including:
          <ul>
            <li>the disclosure of personal data to unauthorised individuals</li>
            <li>loss or theft of data or equipment on which data is stored</li>
            <li>loss or theft of paper records</li>
            <li>inappropriate access controls allowing unauthorised use of information</li>
          </ul>
        </p>
        
        <h4>What Should I Expect After Data Breach Involving My Data?</h4>
        <p>
          There is an obligation on companies to notify the ICO within 72 hours data breach 
          that is likely to result in risk to individuals.
        </p><p>
          There is a requirement to notify individuals if the breach is likely to result in a high 
          risk to them so in such a case you should receive some form of notification from them.
        </p>
        
        <h4>What Can I Do About A Data Breach Involving My Data?</h4>
        <p>
          Individuals have several remedies under the GDPR, including:
          <ul>
            <li>Making a complaint to the ICO</li>
            <li>Making an application to Court for an order requiring you to comply with their individual rights</li>
            <li>Making an application to Court for damages caused by the breach (including damages for distress); and/or</li>
            <li>Making a complaint to the ICO or an application to Court supported by a representative body, such as a trade union.</li>
            <li>Seeking compensation directly from the company.</li>
          </ul>
          Certain breaches of the GDPR can also result in criminal prosecution (e.g., altering an 
          individual’s personal data to prevent its disclosure in response to a SAR, or forcing an 
          individual to make a SAR to access details of their criminal or health records).
        </p>
        
        <h4>What Is Consent For Data Processing?</h4>
        <p>
          Consent is just one of the lawful basis companies can rely on when processing 
          your personal data. It must be clear to individuals what they are providing consent 
          for and any activities to be performed with that data has to be described in 
          the terms of the consent, which has to be accepted by them.
        </p><p>
          The GDPR sets a high bar for consent and has been designed to give data subjects 
          more control over how their data is used. Some of the most important elements of 
          consent under GDPR are:
          <ul>
            <li>Consent requires a positive opt-in and must be explicit</li>
            <li>Pre-ticked boxes or any other method of consent by default are not allowed</li>
            <li>Silence or inactivity do not constitute valid consent.</li>
            <li>Consent for children must be given by the child’s parent or custodian, and verifiable.</li>
            <li>Must be specific, clear and concise with regard to what people are consenting to</li>
            <li>Must be granular, getting separate consent for separate things and not asking for blanket consent</li>
            <li>Consent should not be a pre-condition of accessing a service</li>
            <li>People should be able to withdraw their consent at any time easily</li>
            <li>Records of consent must be kept and details of what people have consented to</li>
          </ul>
        </p>
        
        <h4>Can A Company Only Process My Data With My Consent?</h4>
        <p>
          Not always, companies can have a legal reason to use the personal data you provide to 
          them and they do not need consent from you to process your data for a legitimate purpose.
        </p><p>
          Consent is one lawful/legal basis for processing, but there are five others.
        </p><p>
          Companies are expected to choose the lawful basis that most closely reflects the true 
          nature of their relationship with you, the individual and the purpose of the processing. 
          If consent is difficult, this is often because another lawful basis is more appropriate, 
          so all the alternatives will be considered. They are as follows;
        </p>
        
        <h4>What Is A Subject Access Request (SAR)?</h4>
        <p>
          A SAR is a request from an individual (data subject) to understand what personal data is 
          being held, that it is accurate and how it is being used.
        </p>
        
        <h4>What Should My Subject Access Request Contain?</h4>
        <p>
          There is no prescribed method by which a SAR can be made. It could be submitted verbally, 
          in writing or even be made on social media channels. 
        </p><p>
          It does not even have to include the phrase ‘subject access request’ and companies can no 
          longer insist on a SAR being made in writing.
        </p>
        
        <h4>What Do I Do If I Discover My Data Is Not Secure?</h4>
        <p>
          You should contact the company processing your data and query this. They are legally 
          obligated to respond to you within 30 days with full details of what action is to be 
          taken to rectify the matter.
        </p><p>
          If they fail to respond or fail to provide a satisfactory response, you can make a 
          complaint to the ICO.
        </p>
        
        <h4>How Do I Complain To The Information Commissioner’S Office (ICO)?</h4>
        <p>
          You can make a complaint to the Information Commissioner’s Office at: <br/>
            <section style={{marginTop: '8px', marginLeft: '12px', fontSize: '16px'}}>
              <strong>
              First Contact Team <br/>
              Information Commissioner’s Office <br/>
              Wycliffe House <br/>
              Water Lane <br/>
              Wilmslow <br/>
              SK9 5AF <br/>
              </strong>
            </section>
        </p>
        
        <h4>Can I Complain About A Company Outside The United Kingdom?</h4>
        <p>
          Yes, you can, so long as their business targets UK residents specifically.
        </p>
        
        <h4>Does The GDPR Apply To My Neighbour’s CCTV Cameras?</h4>
        <p>
          The GDPR does not apply to the use of data for a purely personal or household activity.
        </p>

        <h4>What Is PECR?</h4>
        <p>
          The Privacy and Electronic Communications Regulations (PECR) governs matters such as 
          marketing communications, website cookies and customer data and sets out the rules for 
          carrying out unsolicited direct marketing by email, phone, fax, text, other electronic 
          messages (such as picture/video messages, voicemails and direct messages via social media).
        </p><p>
          It does not apply to marketing by post or online advertising.
        </p><p>
          The Privacy and Electronic Communications Regulations are specific to the UK, applying 
          only to UK businesses.
        </p><p>
          Website owners in the UK will be required to comply with PECR even if the website itself 
          is hosted outside the UK – and the same will be true for international website owners 
          creating material intended for UK audiences.
        </p><p>
          Consumers, sole traders, partnerships and employees are all treated as individuals 
          and specific consent is needed to send a message.
        </p>

        <h4>What Are The Penalties For Non-Compliance?</h4>
        <p>
          The penalties for data breaches have increased significantly under the GDPR and so it 
          more important than ever for everyone to be taking appropriate security measures and 
          report any security breaches.
        </p><p>
          For (mainly) a breach of record keeping, contracting and security clause:
          <ul>
            <li>Maximum fine of up to 10 million euros or 2% or annual worldwide turnover, whichever is greater.</li>
          </ul>
          For (mainly) a breach of basic principles, data subject rights, transfer to third countries:
          <ul>
            <li>Maximum fine of up to 20 million euros or 4% or annual worldwide turnover, whichever is greater.</li>
          </ul>
          It is important that the companies are quick and reactive in the event of a suspected 
          data breach. The level of fine will depend on any remedial action they take and their 
          willingness to co-operate with the ICO.
        </p><p>
          <strong>FOR ANY QUESTIONS NOT COVERED HERE, PLS CONTACT US DIRECTLY.</strong>
        </p>
      </div>
      <div className={styles.Ads_2}></div>
      <div></div>
    </div>
  )
}

export default HowWeHelp;
