import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router-dom';
import Button from '../UI/Button';
import styles from './GuideDetail.module.css';
import 'react-quill/dist/quill.snow.css';

const GuideDetail = ({ guide = {}, upsetGuide }) => {
  const _guide = {...guide};
  const [title, setTitle] = useState(_guide?.title);
  const [guideDetail, setGuideDetail] = useState(_guide?.detail);
  const history = useHistory();

  const btnClickHandler = () => {
    if(!title || !guideDetail) return;
    if (title.trim().length === 0 || guideDetail.trim().length === 0) return;
    _guide.title = title;
    _guide.detail = guideDetail;
    upsetGuide(_guide);
  }

  return (
    <>    
      <div className={styles.GuideDetail}>
        <input type='text' placeholder='guide title' value={title} onChange={(e) => setTitle(e.target.value)} />
        <div>
          <ReactQuill theme='snow' value={guideDetail} onChange={setGuideDetail} >
            <div className={styles.QE} />
          </ReactQuill>
        </div>
        <div className={styles.HoriBtns}>
          <Button txt={_guide.id ? 'Update Guide' : 'Create Guide'} btnClicked={btnClickHandler} />
          <Button txt='Go Back' btnClicked={() => history.goBack()} />
        </div>
      </div>
    </>
  )
}

export default GuideDetail;
