import React, { useState } from 'react';
import DropMenu from '../UI/DropMenu/DropMenu';
import menu_icon from '../../images/menu_icon.png';
import styles from './MenuBtn.module.css';

const MenuBtn = () => {
  const [showMenu, setShowMenu] = useState(false);

  const hideMenu = () => (setShowMenu(false));

  return (
    <>
      {showMenu ? <DropMenu hide={hideMenu} /> : null}
      <div className={styles.MenuBtn} onClick={() => setShowMenu(true)}>
        <img src={menu_icon} alt='menu_icon' />
      </div>
    </>
  )
}

export default MenuBtn;
