import React from 'react';
import Button from '../UI/Button';
import { Link, useHistory } from 'react-router-dom';
import styles from './x404.module.css';

const X404 = () => {
  const history = useHistory();
  const goHome = () => {
    history.push('/');
  }

  return (
    <div className={styles.x404}>
      <p>
        404 <br />
        Page Not Found <br />
        <span>
          Sorry, the content you are looking for does not exist<br />
          either it has been removed or you mistyped the address
        </span>
      </p>
      <div className={styles.Btns}>
          <Button txt='Go To Homepage' btnClicked={goHome} />
        <Button txt='Contact Us' btnClicked={null} />
      </div>
    </div>
  )
}

export default X404;