import React from 'react';
import Logo from '../Logo/Logo';
import Navigation from '../navigation/Navigation';
import styles from './Header.module.css';

const Header = () => {
  return (
    <div data-testid='header' className={styles.Header}>
      <div className={styles.HeaderTop}>
        <div>
          <Logo />
        </div>
        <div className={styles.NavAccArea}>
          <Navigation />
        </div>
      </div>
      <div>
        <span className={styles.Caption}>The Free Data Protection Complaints Management Tool</span>
      </div>
    </div>
  )
}

export default Header;
