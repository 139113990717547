import React, { useState } from 'react';
import ActionBtns from '../ActionBtns';
import styles from './Common.module.css';

const About = () => {  
  const who_we_are = <LoremIpsum tag='WHO_WE_ARE' />;
  const mission = <LoremIpsum tag='MISSION' />;
  const objectives = <LoremIpsum tag='OBJECTIVES' />;
  const [selected, setSelected] = useState(who_we_are);

  return (
    <div className={styles.Common}>
      <div className={styles.ContentNav}>
        <div>
          <div className={styles.WideOnly}>
            About
          </div>
          <div className={styles.ContentNavMenus}>
            <span className={styles.NavMenu} onClick={() => setSelected(who_we_are)} >Who We Are</span>
            <span className={styles.NavMenu} onClick={() => setSelected(mission)} >Mission Statement</span>
            <span className={styles.NavMenu} onClick={() => setSelected(objectives)} >Objectives</span>
          </div>
        </div>
        <div className={styles.ContentNavBtns}>
          <ActionBtns />
        </div>
      </div>
      <div data-testid='ads_area_1' className={styles.Ads_1}></div>
      <div data-testid='txt_area' className={styles.Center}>
        {selected}
        <div className={styles.CenterBtns}>
          <ActionBtns />
        </div>
      </div>
      <div data-testid='ads_area_2' className={styles.Ads_2}></div>
    </div>
  )
}

export const LoremIpsum = ({ tag = 'DEFAULT' }) => {
  const WHO_WE_ARE = <div>
      <h4>Who We Are</h4>
      <p>
      We are qualified and experienced data protection professionals giving back to society.<br/>
      We are impartial and independent, and every day we provide advice and support to enable people understand 
      their rights and free content which inform and educate.
      </p>
    </div>;

  const MISSION = <div>
    <h4>Mission Statement</h4>
    <p>
      Our mission is to continually ensure that everyday people understand what their personal 
      data is and can be used for and support them in exercising their rights in line with the 
      General Data Protection Regulation (GDPR), the UK GDPR and the Data Protection Act 2018.
    </p>  
    </div>;

  const OBJECTIVES = <div>
      <h4>Objectives</h4>
      <p>
        <ul>
          <li>Contribute to society by providing a free service</li>
          <li>Perform the mission statement</li>
        </ul>
      </p>
    </div>;

  return (<>
            {tag === 'WHO_WE_ARE'
              ? WHO_WE_ARE
              : tag === 'MISSION'
                ? MISSION
                : tag === 'OBJECTIVES'
                  ? OBJECTIVES
                  : <p>
                      {tag}: Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Ipsum augue libero quam massa.Amet in eu faucibus ornare. Ac nascetur 
                      et dui diam quis aliquet sagittis venenatis suspendisse. Venenatis 
                      diam ipsum viverra ut ipsum, urna. Adipiscing id lacus, posuere amet.
                      Suspendisse adipiscing bibendum enim id eu dui aliquam quisque ut. 
                      Facilisi vitae libero sed orci.
                    </p>
            }
          </>
  );
}

export default About;
