import React from 'react';
import ActionBtns from '../ActionBtns';
import styles from './Common.module.css';

const Terms = () => {
  return (
    <div className={styles.Common}>
      <div className={styles.ContentNav}>
        <div>
          Terms and Conditions
        </div>
        <div className={styles.ContentNavBtns}>
          <ActionBtns />
        </div>
      </div>
      <div className={styles.Ads_1}></div>
      <div className={styles.Center}>
        <h3>Terms and Conditions</h3>
        <p>
          These terms and conditions apply to the Data Mediator Group and sets out the terms of 
          use on which you may make use of the website www.datamediator.co.uk (“Site”).
        </p>

        <h3>Acceptance of these Terms and Conditions</h3>
        <p>
          Please read these terms carefully before using this website (“Site”). If you access and 
          use our website you agree to be bound by and act in accordance with these Terms and Conditions 
          and our Privacy Policy. If you do not, or cannot, agree to these Terms and Conditions or 
          our Privacy Policy, you are not permitted to access and use the website and you should immediately 
          stop using or accessing the content. If you are in breach of these Terms and Conditions, 
          your right to access and use the website will cease immediately.
        </p>
        <p>
          You agree to use this Site only for lawful purposes, and in a manner which does not infringe 
          the rights of, restrict, or inhibit the use and enjoyment of this Site by any third party. 
          Such restrictions and inhibitions include, without limitation, conduct which is unlawful or 
          which may harass or cause distress or inconvenience to any person, the transmission of obscene 
          or offensive material or disruption of normal flow of dialogue within this Site.
        </p>

        <h3>Our Services</h3>
        <p>
          Data Mediator is a free advice and support service for private individuals with data protection concerns. Therefore, you may only use our service if you are:
          <ul>
            <li>
              A private individual who is seeking advice on and/or support with your data protection issues; or
            </li>
            <li>
              A private individual acting on behalf of another private individual who needs advice on and/or 
              support with their data protection issues.
            </li>
          </ul>
          The material on this Site is provided for the purposes of information purposes only. Independent advice should be sought on financial and legal matters.
        </p>

        <h3>Your Responsibility</h3>
        <p>
          In order to obtain our advice and support, you will need to contact us via email or 
          appointment routes and provide details of your data protection issue.
        </p>
        <p>
          If you intend to share any details about other individuals with us, you must obtain their 
          permission before you provide us with any of their information. In submitting any other 
          person's details, you are confirming to us that you have their permission to do so, and that 
          they understand how their information will be used.
        </p>
        <p>
          The responses we provide you with will depend on what information you provide us with. 
          You are solely responsible to ensure that the information you give us is true, accurate 
          and complete. We cannot be held responsible for any inaccurate information.
        </p>

        <h3>Website Content</h3>
        <p>
          We have taken every reasonable step to make sure the information contained in this Site is 
          accurate and up-to-date. However, we can accept no liability for any errors or omissions. 
          We reserve the right to add, amend or delete information from this Site at any time without notice. 
        </p>
        <p>
          While Data Mediator endeavours to ensure that the Site is available 24 hours a day, this is 
          not guaranteed. Data Mediator does not warrant that the functions contained in the material 
          in this Site will be uninterrupted or error free, that defects will be corrected, or that 
          this Site or the server that makes it available are free of viruses or bugs. 
        </p>
        <p>
          While we take every reasonable step to counteract viruses and other things with contaminating 
          or destructive properties, we can give no warranty that this site and its content are free from 
          viruses or anything else that has such contaminating or destructive properties.
        </p>
        <p>
          We are not responsible for any commentary, opinions, ratings or other postings on our website 
          by any third party.
        </p>

        <h3>Copyright</h3>
        <p>
          The copyright in the material is owned by the Data Mediator Group and/or its content suppliers. 
          Any person who believes they own the copyright for material published on this Site, please contact us.
        </p>
        <p>
          Unauthorised use of the material including reproduction, storage, modification, distribution 
          or publication without the prior written consent of Data Mediator and the respective copyright 
          owner(s) is prohibited. Documents (including information, images, photos, logos, names and icons) 
          may not be downloaded, altered or adapted without the prior written permission of the copyright 
          holders on this Site for any purpose except for personal, non-commercial use. 
        </p>
        <p>
          Should we receive any complaint with regard to third party material on this Site, it will review 
          the matter and remove material at its sole discretion. Data Mediator will have no further liability 
          to any party in such instance.
        </p>

        <h3>Liability</h3>
        <p>
          Please read the following limits and exclusions carefully.
        </p>
        <p>
          These terms do not exclude our liability to you in any way for: personal injury or death caused 
          by our negligence; fraud or fraudulent statements made by one of our employees or agents for or 
          any matter for which it would be illegal for us to exclude or to attempt to exclude our liability. 
          In addition, we are only liable for losses which are a foreseeable consequence of us breaking this 
          contract. Losses are foreseeable where they could be contemplated by you and us at the time your 
          information is inputted onto our website. 
        </p>
        <p>
          Data Mediator does not accept liability for any losses or damages whatsoever, including, without 
          limitation, indirect or consequential damages, loss of profits, or any damages whatsoever arising 
          out of, or in connection with the use or loss of use of this Site which you suffer and which are not 
          foreseeable by you and us at the time we enter into this contract, including, but not limited to: 
          loss of income or revenue; loss of business; loss of data; loss of profits or contracts; loss of 
          anticipated savings or waste of management or office time however arising.
        </p>
        <p>
          In no circumstance will we be responsible for any losses which arise in connection with an event 
          or series of events which is/are outside of our reasonable control.
        </p>

        <h3>Complaints</h3>
        <p>
          We aim to provide a high standard of service, so please let us know if we fall short by contacting 
          us with your complaints on dpo@datamediator.co.uk
        </p>
        <p>
          We take all complaints very seriously.
        </p>

        <h3>Changes</h3>
        <p>
          Data Mediator reserves the right to make changes to these terms and conditions by posting the 
          changes online. 
        </p>
        <p>
          Your continued use of this Site after changes are posted constitutes your acceptance of this 
          agreement as modified.
        </p>
        <p>
          We reserve the right to make changes to the material on this Site, or to the information in them, 
          at any time without notice. 
        </p>
        <p>
          The material on this Site may be out of date, and we make no commitment to update them.
        </p>

        <h3>Applicable Law</h3>
        <p>
          These Terms and Conditions shall be governed and construed in accordance with the laws of England and Wales. 
        </p>
        <p>
          Any disputes arising here shall be exclusively subject to the jurisdiction of the courts of England and Wales.
        </p>

      </div>
      <div className={styles.Ads_2}></div>
      <div></div>
    </div >
  )
}

export default Terms;
