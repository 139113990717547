import React from 'react';
import SiteMap from '../SiteMap';
import Legal from '../Legal';
import Copyright from '../Copyright';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div data-testid='footer' className={styles.Footer}>
      <div className={styles.SiteLegal}>
        <SiteMap />
        <Legal />
      </div>
      <div>
        <Copyright/>
      </div>
    </div>
  )
}

export default Footer;
