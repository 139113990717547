import React from 'react';
import styles from './Content.module.css';
import Home from '../pages/Home';
import About from '../pages/About';
import HowWeHelp from '../pages/HowWeHelp';
import FAQ from '../pages/FAQ';
import Guides from '../pages/Guides';
import Admin from '../pages/Admin';
import Terms from '../pages/Terms';
import Policy from '../pages/Policy';
import X404 from '../pages/x404';
import { Switch, Route } from "react-router-dom"; 

const Content = () => {
  return (
    <div data-testid='content' className={styles.Content}>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route exact path='/about'>
          <About />
        </Route>
        <Route exact path='/how-we-help'>
          <HowWeHelp />
        </Route>
        <Route exact path='/faq'>
          <FAQ />
        </Route>
        <Route exact path='/terms'>
          <Terms />
        </Route>
        <Route exact path='/policy'>
          <Policy />
        </Route>
        <Route path='/guides/:guideId'>
          <Guides />
        </Route>
        <Route path='/admin'>
          <Admin />
        </Route>
        <Route path='*'>
          <X404 />
        </Route>
      </Switch>
    </div>
  )
}

export default Content;
