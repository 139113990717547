import React from 'react';
import SearchBar from '../SearchBar';
import styles from './Home.module.css';
import CardsArea from '../CardsArea';

const Home = () => {
  return (
    <div className={styles.Home}>
      <div data-testid='hero_img' className={styles.Hero_Img} ></div>
      <div className={styles.Hero_Area} data-testid='home'>
        <span className={styles.Txt_1} >Secure Your Personal Data</span>
        <span className={styles.Txt_2} >Expert GDPR professional advice for individuals</span>
        <span className={styles.Txt_3} >Find solutions for personal data concerns</span>
        <SearchBar />
      </div>
      <span className={styles.Txt_4}>Free Advice and Support for Individuals</span>
      <CardsArea />
      <div className={styles.Cards} >

      </div>
    </div>
  )
}

export default Home;
