import React from 'react';
import styles from './Button.module.css';

const Button = ({ btnClicked, txt = 'Submit', testId = 'btn'}) => {
  return (
    <div data-testid={testId} onClick={() => btnClicked()}  >
      <span className={styles.Button} >{txt}</span>
    </div>
  )
}

export default Button;
