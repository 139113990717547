import React from 'react';
import ActionBtns from '../ActionBtns';
import styles from './Common.module.css';

const Policy = () => {
  return (
    <div className={styles.Common}>
      <div className={styles.ContentNav}>
        <div>
          Privacy Policy
        </div>
        <div className={styles.ContentNavBtns}>
          <ActionBtns />
        </div>
      </div>
      <div className={styles.Ads_1}></div>
      <div className={styles.Center}>
        <p>
          We understand that your privacy is extremely important and we are committed to processing your personal data in a transparent and lawful way. We will comply with and be accountable for the Data Protection Act 2018 and the EU General Data Protection Regulations (EU GDPR) and the UK General Data Protection Regulations (UK GDPR).
        </p>
        <p>
          This policy applies if you interact with us online, via our website and sets out how we collect your personal data, what we do with it and your rights in relation to the personal information we hold about you.
        </p>

        <h3>How We Use Your Information</h3>
        <p>
          We may use your information in the following ways:
          <ol>
            <li>
              Contacting you: We use your personal information to contact you. This may be in relation
              to an issue you have raised with us as a client or to ask for your feedback.
            </li>
            <li>
              Contacting third parties: We use the information you provide us with to contact any relevant third parties. We will only do this with your consent.
            </li>
          </ol>
        </p>

        <h3>Information We Collect</h3>
        <p>
          The categories of personal data we collect are;
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Enquiry details</li>
          </ul>
        </p>

        <h3>Who We Share Your Information With</h3>
        <p>
          Where necessary or required we share information with:
          <ul>
            <li>Representatives of clients</li>
            <li>Current past and prospective employers of clients</li>
            <li>Applicable establishments identified by clients</li>
            <li>Applicable regulatory bodies</li>
          </ul>
          We use the following system;
          <ul>
            <li>Microsoft 365: to store your information and manage our meetings with you.</li>
          </ul>
        </p>

        <h3>Legal Basis</h3>
        <p>
          Whenever we process your personal information, we have to have in place something
          called a “legal basis” for what we do.
        </p>
        <p>
          We would usually collect your personal data based on your consent.
        </p>
        <p>
          However, the different legal bases we can rely on are:
          <ol>
            <li><strong>Consent:</strong> You have given your consent for us to process your personal
              information for a specific purpose.</li>
            <li><strong>Legitimate interests:</strong> The processing is necessary for us to
              conduct our business, but not where our interests are overridden by your interests or rights.</li>
            <li><strong>Performance of a contract:</strong> We must process your personal information
              in order to be able to provide you with one of our products or services.</li>
            <li><strong>Vital interests:</strong> The processing of your personal information is
              necessary to protect you or someone else’s life.</li>
            <li><strong>Legal obligation:</strong> We are required to process your personal information by law.</li>
          </ol>
        </p>

        <h3>How Long We Keep Your Information</h3>
        <p>
          We will keep your personal information for the purposes set out in this privacy policy
          and in accordance with the law and relevant regulations. We will never retain your personal
          information for longer than is necessary.
        </p>
        <p>
          In most cases, our retention period will come to an end 12 months after the end of your
          relationship with us.
        </p>
        <p>
          We are committed to ensuring that your personal data is secure. To prevent unauthorised
          access or disclosure, we have put appropriate technical and organisations measures in place
          to safeguard your information.
        </p>

        <h3>Your Rights</h3>
        We shall process your information in adherence to your individual rights under the data protection legislation. <br/><br/>

        <strong> 1 Right to be informed</strong> <br/>
        This is your right know about how your data is being processed, who it is given to,
        for what purpose and anything else that guarantees your rights, this is sometimes
        referred to as a privacy notice or a fair processing notice.<br/><br/>

        <strong> 2 Right of access</strong> <br/>
        You have a right to access your personal data. This is known as a Subject Access
        Request (SAR). We may ask you for further information in relation to your request
        to help locate the information you have asked for.<br/><br/>

        <strong> 3 Right to rectification</strong> <br/>
        If you believe that any personal data, we are holding about you is incorrect or
        incomplete, you have the right to request to have your personal data rectified if
        it is inaccurate or incomplete.<br/><br/>

        <strong> 4 Right to erasure</strong> <br/>
        This is also known as “the right to be forgotten”. You have a right to request that
        we erase all your personal data.<br/><br/>

        <strong> 5 Right to restrict processing</strong> <br/>
        You have a right to request that we restrict the processing of your personal data.<br/><br/>

        <strong> 6 Right to data portability</strong> <br/>
        You have the right to obtain and reuse your personal data for your own purposes.
        You have the right to receive your personal data in a structured, commonly used
        and machine-readable format. <br />
        Note that this right only applies to automated information which you initially provided
        consent for us to use, or where we need the information to perform a contract for you.<br/><br/>

        <strong> 7 Right to object to processing</strong> <br/>
        You have the right to object to processing for certain circumstances.<br/><br/>

        <strong> 8 Right to withdraw consent</strong> <br/>
        If you have consented to the processing of your personal data, you have the right to
        revoke such consent at any time.<br/><br/>

        <strong> 9 Rights related to automated decision-making including profiling</strong><br/>
        We do not carry out any automated decision making or profiling.<br/><br/>

        <h3>Contact Us</h3>
        <p>
          If you have any questions about this notice or if you would like to exercise any rights you may
          have in relation to your personal data, please contact the Data Protection Officer: dpo@datamediator.co.uk
        </p>

        <h3>Policy Changes</h3>
        <p>
          We may change this notice from time to time to reflect changes in processing or security updates.
          We encourage you to periodically review this page for the latest information.<br />
          This privacy policy was most recently updated in October 2021.
        </p>
      </div>
      <div className={styles.Ads_2}></div>
      <div></div>
    </div>
  )
}

export default Policy;
