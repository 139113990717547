import firebase from "firebase/app";
import 'firebase/auth';

const {
  REACT_APP_HOME,
  REACT_APP_HOME_DEV,
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL,
  REACT_APP_DATABASE_URL_DEV,
  REACT_APP_PROJECT_ID,
  REACT_APP_PROJECT_ID_DEV,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MSG_SENDER_ID,
  REACT_APP_APP_ID,
  REACT_APP_MEASURE_ID,
  REACT_APP_BOOKING
} = process.env;

export const BOOKING_URL= REACT_APP_BOOKING;

const RUN_ENV = process.env['NODE_ENV'].toLowerCase();
// const RUN_ENV = 'production';

export const app_Config = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: RUN_ENV === 'development'
    ? REACT_APP_DATABASE_URL_DEV
    : REACT_APP_DATABASE_URL,
  projectId: RUN_ENV === 'development'
    ? REACT_APP_PROJECT_ID_DEV
    : REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MSG_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASURE_ID
}

export const auth_UI_Config = {
  tosURL: RUN_ENV === 'development'
    ? `${REACT_APP_HOME_DEV}/terms`
    : `${REACT_APP_HOME}/terms`,
  privacyPolicyUrl: RUN_ENV === 'development'
    ? `${REACT_APP_HOME_DEV}/policy`
    : `${REACT_APP_HOME}/policy`,
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      disableSignUp: {
        status: true
      }
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      disableSignUp: {
        status: true,
        // adminEmail: 'burabari-admin@codebase101.com',
        // helpLink: 'http://localhost:5000'
      }
    }
  ],
  signInSuccessUrl: RUN_ENV === 'development'
    ? `${REACT_APP_HOME_DEV}/admin`
    : `${REACT_APP_HOME}/admin`,
  signInFlow: 'popup',
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,

  },
};
