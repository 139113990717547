import React from 'react';
import styles from './NavMenu.module.css';

const NavMenu = ({txt = 'Menu', active = false}) => {
  return(
    <span className={styles.NavMenu} style={active ? {color: '#FF8888'} : null} >{txt}</span>
  )
}

export default NavMenu;
