import React from 'react';
import styles from './GuidesList.module.css';

const GuidesList = ({ guides = [], showGuide, deleteGuide }) => {
  const docIcon = <span className="material-icons" style={{ marginRight: '10px' }}>&#xEF42;</span>;

  return (
    <div className={styles.GuidesList}>
      {guides.length === 0
        ? <span>Please select New Guide menu to create guides.</span>
        : guides.map(g => (
          <>
          <div className={styles.GuideItem} key={g.id} >
            <div  style={{display: 'flex', alignItems: 'center'}} 
                  onClick={(e) => {e.preventDefault(); showGuide(g)}} > {docIcon} {g.title} </div>
            <span className="material-icons" style={{ marginLeft: '30px' }} onClick={(e) => {e.preventDefault(); deleteGuide(g.id)}} >&#xe872;</span> 
          </div>
          </>
        ))}
    </div>
  )
}

export default GuidesList;
