import React, { useState } from 'react';
import Button from './UI/Button';
import ContactForm from './UI/ContactForm/ContactForm';
import {BOOKING_URL as url} from '../auth-config';
import styles from './ActionBtns.module.css';

const ActionBtns = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const hideModal = () => (setShowContactModal(false));

  const bookAppointment = () => {
    window.open(url, '_blank').focus();
  }

  return (
    <>
      {showContactModal ? <ContactForm hide={hideModal} /> : null}
      <div data-testid='side_btns' className={styles.Btns}>
        <span className={styles.Txt}>If you would like further support, click here</span>
        <Button btnClicked={() => setShowContactModal(true)} txt='Contact For Further Support' />
        <span className={styles.Txt}>If you would like to chat with us, click here</span>
        <Button btnClicked={bookAppointment} txt='Book An Appointment' />
      </div>
    </>
  )
}

export default ActionBtns;
