import React from 'react';
import Card from './Card';
import chart from '../images/chart.png';
import database from '../images/database.png';
import feather from '../images/feather.png';
import eye_off from '../images/eye-off.png';
import padlock from '../images/padlock.png';
import styles from './CardsArea.module.css';

const CardsArea = () => {
  return(
    <div className={styles.CardsArea}>
      <Card image={padlock} alternateTxt='padlock' txt='Understand how your data is collected and secured' />
      <Card image={chart} alternateTxt='chart' txt='Challenge Marketing practices' />
      <Card image={database} alternateTxt='database' txt='Query Data Misuse &amp; Data Breaches' />
      <Card image={eye_off} alternateTxt='eye_off' txt='Seek compensation for non-compliance' />
      <Card image={feather} alternateTxt='feather' txt='Complain to the regulatory authority' />
    </div>
  )
}

export default CardsArea;
