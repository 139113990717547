import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebase } from "../contexts/FirebaseProvider";
import ContactForm from './UI/ContactForm/ContactForm';
import search_icon from '../images/icon-search.png';
import styles from './SearchBar.module.css';

const SearchBar = () => {
  const ctx = useFirebase();
  const guides = ctx.guides;
  const [fieldValue, setFieldValue] = useState();
  const [foundGuides, setFoundGuides] = useState([]);
  const history = useHistory();

  const [showContactModal, setShowContactModal] = useState(false);
  const hideModal = () => (setShowContactModal(false));

  const searchTopics = (e) => {
    e.preventDefault();
    const txt = e.target.value;
    if (txt.trim().length < 1) {
      setFoundGuides([]);
      setFieldValue(txt);
      return;
    }
    const filter = guides.filter(p => (p.title.toLowerCase().includes(txt.toLowerCase())));
    const filtered = [...filter, { id: 'XXAXX', title: 'Contact Us' }];
    // const mapped = filtered.map( guide => guide.title );
    setFoundGuides(filtered);
    setFieldValue(txt);
  }

  const selectTopic = (id) => {
    if (id === 'XXAXX') {
      setShowContactModal(true);
    } else {
      history.push(`/guides/${id}`);
    }
  }

  return (
    <>
      {showContactModal ? <ContactForm hide={hideModal} /> : null}
      <div className={styles.SearchWrapper}>
        <div data-testid='search_bar' className={styles.SearchBar}>
          <input title='search_txt'
            placeholder='Find GDPR topics'
            value={fieldValue}
            onChange={searchTopics}
            onBlur={() => {
              setTimeout(() => setFoundGuides([]), 200);
              // setFoundTopics([]);
              // setFieldValue('');
            }}
          />
          <img src={search_icon} alt='search_icon' />
        </div>
        <div className={styles.Results}>
          {foundGuides.map(g => <span key={g.id} onClick={() => selectTopic(g.id)} >{g.title}</span>)}
        </div>
      </div>
    </>
  )
}

export default SearchBar;
