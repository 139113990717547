import React from 'react';
import { Link } from "react-router-dom";
import styles from './Legal.module.css';

const Legal = () => {

  const itemClicked = () => {
    console.log('LegalItem clicked');
  }

  return (
    <div className={styles.Legal}>
      <div>Legal</div>
      <div className={styles.LegalLinks}>
        <Link to='/terms'>
          <LegalItem txt='Terms &amp; Conditions' clicked={itemClicked} />
        </Link>
        <Link to='/policy'>
          <LegalItem txt='Privacy Policy' clicked={itemClicked} />
        </Link>
      </div>
    </div>
  )
}

export default Legal;

const LegalItem = ({ txt, clicked }) => {
  return (
    <span onClick={clicked} >{txt}</span>
  )
}
