import React from 'react';
import logoImg from '../images/logo_main.png';
import styles from './Copyright.module.css';

const Copyright = () => {
  return (
    <div className={styles.Copyright}>
      <div className={styles.LogoFooter}>
        <img src={logoImg} alt='logo_footer' />
        <span>© 2021 - DataMediator</span>
      </div>
    </div>
  )
}

export default Copyright;
