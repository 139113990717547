import React from 'react';
import styles from './NavFooter.module.css';

const NavFooter = ({txt = 'Menu', clicked}) => {
  return(
    <span className={styles.NavFooter} onClick={clicked} >{txt}</span>
  )
}

export default NavFooter;
