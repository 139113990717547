import React from 'react';
import styles from './SiteMap.module.css';
import { Link } from "react-router-dom";
import NavFooter from './navigation/NavFooter';

const SiteMap = () => {

  return (
    <div className={styles.SiteMap}>
      <div>Site Map</div>
      <div className={styles.SiteMapLinks}>
        <Link to='/'>
          <NavFooter txt='Home' />
        </Link>
        <Link to='/about'>
          <NavFooter txt='About' />
        </Link>
        <Link to='/faq'>
          <NavFooter txt='FAQs' />
        </Link>
        <Link to='/how-we-help'>
          <NavFooter txt='How We Can Help' />
        </Link>
      </div>
    </div>
  )
}

export default SiteMap;
