import React, { useState } from 'react';
import { useFirebase } from "../../../contexts/FirebaseProvider";
import Button from '../Button';
import styles from './ContactForm.module.css';
import { genHtml } from "./EmailTemplate";
import icon_close from '../../../images/icon_close.png';

const ContactForm = ({ hide }) => {
  const ctx = useFirebase();
  const createComplaint = ctx.createComplaint;

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [complaint, setComplaint] = useState('');

  const setData = (e) => {
    e.preventDefault();
    const data = e.target.value;
    const name = e.target.name;
    switch (name) {
      case 'name':
        setFullName(data);
        break;
      case 'email':
        setEmail(data);
        break;
      case 'subject':
        setSubject(data);
        break;
      case 'complaint':
        setComplaint(data);
        break;
      default:
        break;
    }
  }

  const postComplaint = async (senderName, senderEmail, _subject, _complaint) => {
    if(!senderName || !senderEmail || !_subject || !_complaint)
      return;

    const complaintData = {
      from: `Data Mediator <mg@datamediator.co.uk>`,
      to: `dpo@datamediator.co.uk`,
      message: {
        subject: `[CONTACT FORM] ${_subject}`,
        text: `
          Name: ${senderName}   Email: ${senderEmail}   Date: ${new Date(Date.now())}
          Subject: ${_subject}
          Complaint:
          ${_complaint}
          `,
        html: genHtml(senderName, senderEmail, _subject, new Date(Date.now()), _complaint),
        // html: `<strong>Name:</strong> ${senderName} &nbsp;&nbsp;&nbsp;&nbsp;<strong>Email:</strong> ${senderEmail} <br/><br/>
        // <strong>Subject:</strong> ${_subject} <br/><br/>
        // <strong>Complaint:</strong> <br/>
        // ${_complaint}
        // `,
      },
      auto_reply_email: senderEmail,
      auto_reply_subject: _subject,
      auto_reply_name: senderName
    }
      
    createComplaint(complaintData)
      .then(() => hide())
      .catch(err => alert('Failed to submit message. /nPlease try again later.'));
  }

  return (
    <div className={styles.Wrapper}>
      <div className={styles.ContactForm}>
        <form>
          <div className={styles.FormTitle}>
            <span>Contact Us</span>
            <img src={icon_close} alt='close icon' onClick={hide} />
          </div>
          <DataUnit data={fullName} setData={setData} label='Full Name:' name='name'
            isTxtArea={false} placeholder='your full name' />
          <DataUnit data={email} setData={setData} label='Email:' name='email'
            isTxtArea={false} placeholder='email address' />
          <DataUnit data={subject} setData={setData} label='Subject:' name='subject'
            isTxtArea={false} placeholder='' />
          <DataUnit data={complaint} setData={setData} label='Complaint:' name='complaint'
            isTxtArea={true} placeholder='' />
          <Button txt='Submit Complaint' btnClicked={() => postComplaint(fullName, email, subject, complaint)} />
        </form>
      </div>

      <div className={styles.BackDrop} onClick={hide} ></div>
    </div>
  )
}

export default ContactForm;

const DataUnit = ({ label, placeholder, name, isTxtArea, data, setData }) => (
  <div className={styles.DataUnit} key={name}>
    <span>{label}</span>
    {isTxtArea
      ? <textarea name={name} placeholder={placeholder} value={data} onChange={setData} rows={10} required />
      : <input type={'textfield'} name={name}
        placeholder={placeholder} value={data} onChange={setData} rows={30} required />
    }
  </div>
);
