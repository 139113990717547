import React from 'react';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import styles from './Layout.module.css';

const Layout = () => {
  return (
    <div data-testid='layout' className={styles.Layout}>
      <div>
        <Header />
        <Content />
      </div>
      <Footer />
    </div>
  )
}

export default Layout;
