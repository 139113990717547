import './App.css';
import Layout from './components/layouts/Layout';
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App" data-testid='app'>
        <Layout />
      </div>
    </Router>
  );
}

export default App;
