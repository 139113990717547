import React from 'react';
import { Link } from 'react-router-dom';
import logoImg from '../../images/logo_main.png';
import styles from './Logo.module.css';

const Logo = () => {
  return (
    <div className={styles.LogoComp}>
      <div className={styles.LogoName}>
        <Link to='/'>
          <img src={logoImg} alt='logo' />
        </Link>
        <span>DataMediator</span>
      </div>
    </div>
  )
}

export default Logo;