import React from 'react';
import styles from './DropMenuItem.module.css';

const DropMenuItem = ({txt = 'Menu'}) => {
  return(
    <div className={styles.DropMenuItem} >{txt}</div>
  )
}

export default DropMenuItem;
