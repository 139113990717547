
export const genHtml = (name, email, subject, date, complaint) => {
  return `
  <!DOCTYPE html>
  <html>
    <head>
      <style>
        body {
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          font-size: 14px;
          padding: 20px;
        }
  
        .label {
          font-weight: bold;
        }
  
        .cap {
          text-transform: capitalize;
        }
        
        .under {
          text-decoration: underline;
        }
      </style>
    </head>
    <body>
      <p>
        This email is from the contact form.
      </p>
      <div class="line">
        <span class="label">Name:</span> &nbsp; <span id="name">${name}</span> <br/>
        <span class="label">Email:</span> &nbsp; <span id="email">${email}</span> <br/>
        <span class="label">Subject:</span> &nbsp; <span id="subject">${subject}</span> <br/>
        <span class="label">Date:</span> &nbsp; <span id="date">${date}</span> <br/>
        <br/>
      </div>
      <div>
        <span class="label cap under">Complaint</span> <br/>
        <p id="complaint">
          ${complaint}
        </p>
      </div>
    </body>
  </html>
  `  
}
