import React from 'react';
import ActionBtns from '../ActionBtns';
import styles from './Common.module.css';

const HowWeHelp = () => {
  return (
    <div className={styles.Common}>
      <div className={styles.ContentNav}>
        <div>
          How We Can Help
        </div>
        <div className={styles.ContentNavBtns}>
          <ActionBtns />
        </div>
      </div>
      <div className={styles.Ads_1}></div>
      <div className={styles.Center}>
        <p>
          We can provide you with the following
        </p>
        <div>
          <ul>
            <li>Guides that give detailed information about data protection matters</li>
            <li>Practical advice on what to do about your concerns so you can take your own action</li>
            <li>Practical support with your complaint such as taking action on your behalf and dealing with the companies</li>
            <li>Appointments for online chat to discuss your issues directly with us and get immediate advice</li>
          </ul>
        </div>
      </div>
      <div className={styles.Ads_2}></div>
      <div></div>
    </div>
  )
}

export default HowWeHelp;
