import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { useFirebase } from "../../contexts/FirebaseProvider";
import { useParams } from 'react-router-dom';
import ActionBtns from '../ActionBtns';
import Button from '../UI/Button';
import styles from './Common.module.css';

const Guides = () => {
  const ctx = useFirebase();
  const guides = ctx.guides;
  const guide = ctx.guide;
  const alertOpacity = ctx.alertOpacity;
  const alertMsg = ctx.msg;
  const isErr = ctx.isErr;

  const selectGuide = ctx.selectGuide;
  const deSelectGuide = ctx.deSelectGuide;
  const guideDetailById = ctx.guideDetailById;


  const {guideId} = useParams();
  const [alertColor, setAlertColor] = useState('#1D7277');
  const color_OK = '#1D7277';
  const color_ERR = '#AF0606';

  useEffect(() => {
    setAlertColor(isErr ? color_ERR : color_OK);
  }, [alertOpacity])

  useEffect(() => {
    if(guideId && guideId !== 0){
      guideDetailById(guideId);
    }
  }, [guideId])


  return (
    <>
      <div className={styles.Alert} style={{ opacity: alertOpacity, backgroundColor: alertColor }} >{alertMsg}</div>
      <div className={styles.Common}>
        <div className={styles.ContentNav}>
          <div>
            Data Protection Guides
          </div>
          <div className={styles.ContentNavBtns}>
            <ActionBtns />
          </div>
        </div>
        <div className={styles.Ads_1}></div>
        <div data-testid='page_data' className={styles.Center} >
          {guide
            ? <div>
              <Button txt='Go Back' btnClicked={() => deSelectGuide()} />
              <div>
                {parse(guide.detail)}
              </div>
              <Button txt='Go Back' btnClicked={() => deSelectGuide()} />
            </div>
            : <ul className={styles.GuideList}>
              {guides.map(p => <li key={p.id} onClick={() => selectGuide(p)}>{p.title}</li>)}
            </ul>
          }
          <div className={styles.CenterBtns}>
            <ActionBtns />
          </div>
        </div>
        <div className={styles.Ads_2}></div>
        <div></div>
      </div>
    </>
  )
}

export default Guides;
