import React, { useContext, useState, createContext, useEffect } from "react";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore'
import { app_Config } from '../auth-config';

const fbApp = firebase.initializeApp(app_Config);
const fbAuth = firebase.auth();
const firestore = firebase.firestore();

if (process.env['NODE_ENV'].toLowerCase() === 'development' || window.location.hostname.includes('localhost')) {
  fbAuth.useEmulator('http://localhost:9099');
  firestore.useEmulator('localhost', 8484);
}

//Create context (Global)
const fbContext = createContext();

//Expose context
/**
 * 
 * @returns {{firebase, auth, db, guides, msg, isErr, alertOpacity, guide, persistOrUpdateGuide, guideDetailById, 
 *            fetchAllGuides, deleteGuide, createComplaint, selectGuide, deSelectGuide}}
 */
export const useFirebase = () => {
  return useContext(fbContext);
}

//Create and expose a ContextProvider
export const ProvideFirebase = ({ children }) => {
  const ctx_value = useProvideFirebase();
  return (
    <fbContext.Provider value={ctx_value}>
      {children}
    </fbContext.Provider>
  )
}

const useProvideFirebase = () => {
  const [fb, setFb] = useState(fbApp);
  const [auth, setAuth] = useState(fbAuth);
  const [db, setDb] = useState(firestore);
  const [guides, setGuides] = useState([]);
  const [guide, setGuide] = useState(null);
  const [msg, setMsg] = useState('');
  const [isErr, setIsErr] = useState(false);
  const [alertOpacity, setAlertOpacity] = useState('0');

  const doShowAlert = (alertMsg, isError) => {
    setMsg(alertMsg);
    setIsErr(isError);
    setAlertOpacity('1');
    setTimeout(() => setAlertOpacity('0'), 4000)
  }

  const persistOrUpdateGuide = function (guide) {
    const isUpdate = guide.id ? true : false;
    if (!isUpdate) {
      guide = { ...guide, id: genId() };
    }

    return new Promise(async (resolve, reject) => {
      firestore.collection('guides').doc(guide.id).set(guide)
        .then(() => {
          doShowAlert(`${guide.title} ${isUpdate ? 'update' : 'create'} SUCCESS`, false);
          void resolve();  
        }).catch(err => {
          doShowAlert(`Error ${isUpdate ? 'updating' : 'creating'} guide: ${err}`, true);
          reject(err);
        });
    })
  }

  const guideDetailById = (id) => {
    const foundGuide = guides.find(guide => guide.id === id);
    setGuide(foundGuide);
  }

  const fetchAllGuides = () => {
    firestore.collection('guides').onSnapshot(
      snapshot => {
        setGuides(snapshot.docs.map(doc => doc.data()))
      },
      error => {
        doShowAlert(`Error getting guides from database`, true);
      }
    )
  }

  const deleteGuide = (id) => {
    firestore.collection('guides').doc(id).delete()
      .then(() => { doShowAlert(`Guide delete SUCCESS`, false) })
      .catch(err => doShowAlert(`Error deleting guide id ${id}`, true));
  }

  const createComplaint = (complaint) => {
    return firestore.collection('complaints').add(complaint);
  }

  const selectGuide = (guide) => {
    setGuide(guide);
  }

  const deSelectGuide = () => {
    setGuide(null);
  }

  useEffect(() => {
    fetchAllGuides();
  }, [])

  return ({
    firebase: fb,
    auth,
    db,
    guides,
    msg,
    isErr,
    alertOpacity,
    guide,
    persistOrUpdateGuide,
    guideDetailById,
    fetchAllGuides,
    deleteGuide,
    createComplaint,
    selectGuide,
    deSelectGuide
  })
}

/**
 * A random string ID generator
 * @returns a random string
 */
const genId = () => {
  var rand = [];
  const now = new Date().getMilliseconds().toString();
  let seed = 'abcdefghijkmnpqrstuvwxyz';
  for (var i = 0; i < 8; i++) {
    rand.push(seed.charAt(Math.floor(Math.random() * seed.length)))
  }
  return rand.join('').concat(now);
}
