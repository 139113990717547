import React from 'react';
import Logo from '../../Logo/Logo';
import DropMenuItem from './DropMenuItem';
import { Link, useLocation } from 'react-router-dom';
import styles from './DropMenu.module.css';

const DropMenu = ({ hide }) => {
  const loc = useLocation();
  const pathname = loc.pathname;

  return (
    <>
      <div className={styles.DropMenu} >
        <Logo />
        <div className={styles.DropMenus}>
          <Link to='/' onClick={hide} >
            <DropMenuItem txt='Home' active={pathname === '/'} />
          </Link>
          <Link to='about' onClick={hide} >
            <DropMenuItem txt='About' active={pathname === '/about'} />
          </Link>
          <Link to='how-we-help' onClick={hide} >
            <DropMenuItem txt='How We Can Help' active={pathname === '/how-we-help'} />
          </Link>
          <Link to='guides/0' onClick={hide} >
            <DropMenuItem txt='Guides' active={pathname.startsWith('/guides')} />
          </Link>
        </div>
      </div>
      <div className={styles.BackDrop} onClick={hide} ></div>
    </>
  )
}

export default DropMenu;
