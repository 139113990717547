import React from 'react';
import NavMenu from './NavMenu';
import MenuBtn from '../UI/MenuBtn';
import { Link, useLocation } from "react-router-dom";
import styles from './Navigation.module.css';

const Navigation = () => {
  const loc = useLocation();
  const pathname = loc.pathname;

  return (

    <nav data-testid='nav' className={styles.Nav} >
      <div className={styles.Menus}>
        <Link to='/' >
          <NavMenu txt='Home' active={pathname === '/'} />
        </Link>
        <Link to='/about'>
          <NavMenu txt='About' active={pathname === '/about'} />
        </Link>
        <Link to='/how-we-help'>
          <NavMenu txt='How We Can Help' active={pathname === '/how-we-help'} />
        </Link>
        <Link to='/guides/0'>
          <NavMenu txt='Guides' active={pathname.startsWith('/guides')} />
        </Link>
      </div>
      <div className={styles.Hamburger}>
        <MenuBtn />
      </div>
    </nav>

  )
}

export default Navigation;
